import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: []
    },
    reducers: {
        addToWishlist(state, action) {
            const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
            if(isInWishlist > -1){
                cogoToast.info("Producto ya en wishlist", {position: "bottom-left"});
            } else {
                state.wishlistItems.push(action.payload);
                cogoToast.success("Añadido a wishlist", {position: "bottom-left"});
            }
            
        },
        deleteFromWishlist(state, action){
            state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
            cogoToast.error("Quitado desde Wishlist", {position: "bottom-left"});
        },
        deleteAllFromWishlist(state){
            state.wishlistItems = []
        },
          setRandomProducts(state, action) {

            console.log(action.payload)
            
            state.wishlistItems = action.payload;
            cogoToast.success("Random products loaded", {position: "bottom-left"});
        }
    
    },
});

export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist, setRandomProducts  } = wishlistSlice.actions;
export default wishlistSlice.reducer;
